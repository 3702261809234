import AboutEdit from './admin/AboutEdit';
import AdminTable from './admin/AdminTable';
import BannersEdit from './admin/BannersEdit';
import BlogsEdit from './admin/BlogsEdit';
import ContactEdit from './admin/ContactEdit';
import CourseEdit from './admin/CourseEdit';
import DetailsEdit from './admin/DetailsEdit';
import FaqEdit from './admin/FaqEdit';
import FavIcon from './admin/FavIcon';
// import FooterContentEdit from './admin/FooterContentEdit';
import FooterEdit from './admin/FooterEdit';
import HomeEdit from './admin/HomeEdit';
import LogoEdit from './admin/LogoEdit';
import Main from './admin/Main';
import NavbarEdit from './admin/NavbarEdit';
import PrivacyEdit from './admin/PrivacyEdit';
import ProfileEdit from './admin/ProfileEdit';
import ServiceEdit from './admin/ServiceEdit';
import TermsEdit from './admin/TermsEdit';
import './App.css';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from './login/Login';
import Forgot from './login/Forgot';
import Reset from './login/Reset';
import BlogContentEdit from './admin/BlogContentEdit';
import UsersEdit from './admin/UsersEdit';
import UserDetailsEdit from './admin/UserDetailsEdit';
import AdminProfile from './admin/AdminProfile';
import AddBlog from './admin/AddBlog';
import UserQuizzesDetails from './admin/UserQuizzesDetails';
import UserQuizzesEdit from './admin/UserQuizzesEdit';
import QuizzesQuestionDetails from './admin/QuizzesQuestionDetails';
import QuizzesQuestionEdit from './admin/QuizzesQuestionEdit';
import QuizzesQuestionAdd from './admin/QuizzesQuestionAdd';
import DatingGoalsDetails from './admin/DatingGoalsDetails';
import DatingGoalsAdd from './admin/DatingGoalsAdd';
import DatingGoalsEdit from './admin/DatingGoalsEdit';
// import NewRegister from './register/NewRegister';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route exact path="/adminDashboard" element={<Main />} />
        <Route exact path="/" element={<Login />} />
        <Route exact path="/forgot" element={<Forgot />} />
        <Route exact path="/reset" element={<Reset />} />

         <Route exact path="/table" element={<AdminTable />} /> 
         <Route exact path="/logo" element={<LogoEdit />} /> 
         <Route exact path="/favicon" element={<FavIcon />} /> 
         <Route exact path="/banners" element={<BannersEdit />} /> 

         <Route exact path="/navedit" element={<NavbarEdit />} /> 
         <Route exact path="/footeredit" element={<FooterEdit />} /> 
         <Route exact path="/homeedit" element={<HomeEdit />} /> 
         <Route exact path="/aboutedit" element={<AboutEdit />} /> 
         <Route exact path="/serviceedit" element={<ServiceEdit />} /> 
         <Route exact path="/contactedit" element={<ContactEdit />} /> 
         <Route exact path="/contactedit" element={<ContactEdit />} /> 
         <Route exact path="/privacyedit" element={<PrivacyEdit />} /> 
         <Route exact path="/termsedit" element={<TermsEdit />} /> 
         <Route exact path="/detailsedit" element={<DetailsEdit />} /> 
         <Route exact path="/add-blog" element={<AddBlog />} /> 

         <Route exact path="/users-quizzes" element={<UserQuizzesDetails />} /> 
         <Route exact path="/edit-quizzes/:quizId" element={<UserQuizzesEdit />} /> 
         <Route exact path="/usersedit" element={<UsersEdit />} /> 
         <Route exact path="/edit-user/:userId" element={<UserDetailsEdit />} /> 
         <Route exact path="/blogsedit" element={<BlogsEdit />} /> 
         <Route exact path="/blog-content" element={<BlogContentEdit />} /> 
         <Route exact path="/courseedit" element={<CourseEdit />} /> 
         {/* <Route exact path="/footerCedit" element={<FooterContentEdit />} />  */}
         <Route exact path="/faqedit" element={<FaqEdit />} /> 
         <Route exact path="/profileedit" element={<ProfileEdit />} /> 
         <Route exact path="/profile" element={<AdminProfile />} /> 
         <Route exact path="/quiz-question" element={<QuizzesQuestionDetails />} /> 
         <Route exact path="/quiz-question-edit/:quizId" element={<QuizzesQuestionEdit />} />
         <Route exact path="/add-quiz-question" element={<QuizzesQuestionAdd />} /> 
         <Route exact path="/dating-goals" element={<DatingGoalsDetails />} /> 
         <Route exact path="/add-dating-goals" element={<DatingGoalsAdd />} /> 
         <Route exact path="/edit-dating-goals/:datingId" element={<DatingGoalsEdit />} /> 

         {/* <Route exact path="/register" element={<NewRegister />} />  */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
