import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useAuth } from "../AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import axios from "axios";

const UserQuizzesEdit = () => {
  const navigate = useNavigate();
  const { apipath } = useAuth();
  const { quizId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    smartIntellectually: '',
    quizAnswers1: '',
    quizAnswers2: '',
    quizAnswers3: '',
    quizAnswers4: '',
    quizAnswers5: '',
    quizAnswers6: '',
    quizAnswers7: '',
    quizAnswers8: '',
    quizAnswers9: '',
    quizAnswers10: '',    
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Fetch quiz details on component mount
  useEffect(() => {
    const fetchQuizDetails = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${apipath}/quizzes/answers/details/${quizId}`);
        const data = response.data.quizzes;
        setFormData({
          quizAnswers1: data.quizAnswers1 || '',
          quizAnswers2: data.quizAnswers2 || '',
          quizAnswers3: data.quizAnswers3 || '',
          quizAnswers4: data.quizAnswers4 || '',
          quizAnswers5: data.quizAnswers5 || '',
          quizAnswers6: data.quizAnswers6 || '',
          quizAnswers7: data.quizAnswers7 || '',
          quizAnswers8: data.quizAnswers8 || '',
          quizAnswers9: data.quizAnswers9 || '',
          quizAnswers10: data.quizAnswers10 || '',
        });
      } catch (error) {
        console.error("Error fetching quiz answers details:", error);
        iziToast.error({ title: "Error", message: "Failed to load quiz details." });
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuizDetails();
  }, [apipath, quizId]);

  // Handle form submission to update quiz answers
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      // API call to update the quiz answers
      const response = await axios.put(`${apipath}/quizzes/answers/details/${quizId}`, formData);
      if (response.status === 200) {
        iziToast.success({ title: "Success", message: "Quiz answers updated successfully!" });
        navigate("/users-quizzes"); // Redirect after successful update
      }
    } catch (error) {
      console.error("Error updating quiz answers:", error);
      iziToast.error({ title: "Error", message: "Failed to update quiz answers." });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Edit and verify quiz answers</h5>
            <div className="row nav-column">
              <form className="row" onSubmit={handleSubmit}>
                {[
                  { label: 'Quiz Answer 1', name: 'quizAnswers1' },
                  { label: 'Quiz Answer 2', name: 'quizAnswers2' },
                  { label: 'Quiz Answer 3', name: 'quizAnswers3' },
                  { label: 'Quiz Answer 4', name: 'quizAnswers4' },
                  { label: 'Quiz Answer 5', name: 'quizAnswers5' },
                  { label: 'Quiz Answer 6', name: 'quizAnswers6' },
                  { label: 'Quiz Answer 7', name: 'quizAnswers7' },
                  { label: 'Quiz Answer 8', name: 'quizAnswers8' },
                  { label: 'Quiz Answer 9', name: 'quizAnswers9' },
                  { label: 'Quiz Answer 10', name: 'quizAnswers10' },
                ].map(field => (
                  <div className="col-lg-6 mb-3" key={field.name}>
                    <label className="form-label">{field.label}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={`Enter ${field.label.toLowerCase()}`}
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleChange}
                      required
                    />
                  </div>
                ))}

                <div>
                <Link to="/users-quizzes"> <button className="btn btn-primary"> Back
              </button></Link>
                  <button type="submit" className="btn btn-primary" disabled={isLoading}>
                    {isLoading ? "Updating..." : "Update Quiz"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserQuizzesEdit;
