import React, { useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useAuth } from "../AuthContext";
import { Link, useNavigate } from "react-router-dom";
import iziToast from "izitoast";
import axios from "axios";

const QuizzesQuestionAdd = () => {
  const navigate = useNavigate();
  const { apipath } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    quizQuestion: '',
    quizOption1: '',
    quizOption2: '',
    quizOption3: '',
    quizOption4: '',
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission to add a new quiz question
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // API call to add the quiz question
      const response = await axios.post(`${apipath}/quizzes-question/add`, formData);
      if (response.status === 201) {
        iziToast.success({ title: "Success", message: "Quiz question added successfully!" });
        navigate("/quiz-question"); // Redirect after successful addition
      }
    } catch (error) {
      console.error("Error adding quiz question:", error);
      iziToast.error({ title: "Error", message: "Failed to add quiz question." });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Add a new quiz question and options</h5>
            <div className="row nav-column">
              <form className="row" onSubmit={handleSubmit}>
                <div className="col-lg-12 mb-3">
                  <label className="form-label">Quiz Question</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter quiz question"
                    name="quizQuestion"
                    value={formData.quizQuestion}
                    onChange={handleChange}
                    required
                  />
                </div>

                {[ 
                  { label: 'Quiz Option 1', name: 'quizOption1' },
                  { label: 'Quiz Option 2', name: 'quizOption2' },
                  { label: 'Quiz Option 3', name: 'quizOption3' },
                  { label: 'Quiz Option 4', name: 'quizOption4' },
                ].map((field) => (
                  <div className="col-lg-6 mb-3" key={field.name}>
                    <label className="form-label">{field.label}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={`Enter ${field.label.toLowerCase()}`}
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleChange}
                    //   required
                    />
                  </div>
                ))}

                <div>
                  <Link to="/quiz-question">
                    <button className="btn btn-primary">Back</button>
                  </Link>
                  <button type="submit" className="btn btn-primary" disabled={isLoading}>
                    {isLoading ? "Adding..." : "Add Quiz Question"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default QuizzesQuestionAdd;
