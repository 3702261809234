import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const Reset = () => {

    const [ showPassword, setShowPassword ] = useState();
  
    const handleVisibility = (index) => {
      setShowPassword(showPassword === index ? null : index);
    };

  return (
    <>
        <div className="container-fluid login">
        <div className="row login-row">
          <div className="col-lg-6">
            <div className="img-login">
              <img src="imgs/Mobile login-rafiki.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6 login-right">
            <h1>Reset Password</h1>
            <p>Don't worry !</p>
            <form>
              {/* <div className="login-item col-lg-8 mx-auto">
                <label htmlFor="name">Username</label>
                <input type="email"  
                placeholder="Enter your email"
                name="email"
                required  />
              </div> */}
              <div className="login-item col-lg-8 mx-auto">
                <label htmlFor="name">Password</label>
                <div className="input-group login-group">
                  <input
                    type={showPassword === 1 ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter your password"
                    aria-label="password"
                    aria-describedby="basic-addon2"
                    name="password"
                    required 
                  />
                  <div className="input-group-append login-append">
                    <span className="input-group-text" id="basic-addon2">
                      <i className={showPassword === 1 ? "bi bi-eye-slash" : "bi bi-eye"} onClick={() => handleVisibility(1)}></i>
                    </span>
                  </div>
                </div>
                
              </div>

              <div className="login-item col-lg-8 mx-auto">
                <label htmlFor="name">Confirm Password</label>
                <div className="input-group login-group">
                  <input
                    type={showPassword === 2 ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter your password"
                    aria-label="password"
                    aria-describedby="basic-addon2"
                    name="password"
                    required 
                  />
                  <div className="input-group-append login-append">
                    <span className="input-group-text" id="basic-addon2">
                      <i className={showPassword === 2 ? "bi bi-eye-slash" : "bi bi-eye"} onClick={() => handleVisibility(2)}></i>
                    </span>
                  </div>
                </div>
                
              </div>
              <div className="login-button col-lg-8 mx-auto">
                  <Link to="/login"><button>Continue</button></Link>
              </div>
              
              
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Reset