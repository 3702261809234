import React, { useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";

const FavIcon = () => {
  const [imageUrl, setImageUrl] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="Container ">
          <div className="row">
            <div className="col-lg-5">
              <div className="logo-insidebox">
                <div>
                  <h5>Existing Icon</h5>
                  <img
                    id="blah"
                    src="http://placehold.it/180"
                    alt="your icon"
                    className="mt-3"
                  />
                </div>
                <div className="pt-5">
                  <h5>Change Icon</h5>
                  <input
                    type="file"
                    onchange={handleFileChange}
                    className="pt-3"
                  />
                  {imageUrl && (
                    <img
                      src={imageUrl}
                      alt="Preview"
                      style={{ width: "100px", height: "100px" }}
                    />
                  )}
                </div>
                <button type="submit" className="btn btn-success mt-3">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FavIcon;
