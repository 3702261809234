import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import Navbar from './Navbar';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import moment from 'moment';

const DatingGoalsDetails = () => {
    const dataTableRef = useRef();
    const [show, setShow] = useState(false);
    const [datingGoalsIdToDelete, setDatingGoalsIdToDelete] = useState(null);

    const handleShow = (id) => {
        setDatingGoalsIdToDelete(id);
        setShow(true);
    };
    const handleClose = () => setShow(false);

    const [datingGoals, SetDatingGoals] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { apipath } = useAuth();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchDatingGoals();
        if (!loading && datingGoals.length > 0) {
            $(dataTableRef.current).DataTable();
        }
    }, [loading, datingGoals]);

    const fetchDatingGoals = async () => {
        try {
            const response = await axios.get(`${apipath}/dating-goals/details`);
            const filteredDatingGoals = response.data.datingGoals;
            SetDatingGoals(filteredDatingGoals);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching datingGoals:', error);
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        try {
            setIsLoading(true);
            await axios.delete(`${apipath}/dating-goals/details/${datingGoalsIdToDelete}`);
            await fetchDatingGoals(); // Refresh datingGoals after deletion
            setShow(false);
            setIsLoading(false);
        } catch (error) {
            console.error('Error deleting datingGoals:', error);
            setIsLoading(false);
        }
    };

    return (
        <>
            <SideBar />
            <Navbar />

            <section className='home'>
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className='bi bi-menu'></i>
                </div>

                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Dating Goals</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-content1">
                            <p>Are you sure you want to delete this dating goals?</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="modal-footer-btn" onClick={handleDelete}>
                            Delete
                        </button>
                    </Modal.Footer>
                </Modal>

                <div className="container">
                    <div className="row foot-tablerow">
                        <h5>Dating Goals Details</h5>

                        <div className="add-button">
                            <Link to="/add-dating-goals"><button>+ Add Dating Goals</button></Link>
                        </div>
                        <div className="col-lg-12 maintable-column">
                            <div className="container mt-4 overflow-auto">
                                <table id="datatable" className="table" ref={dataTableRef}>
                                    {/* <table id="datatable" className="table" > */}
                                    <thead>
                                        <tr>
                                            <th>S. No.</th>
                                            <th>Dating Goals Option</th>
                                            <th>Dating Goals Date</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {datingGoals.map((datingGoal, index) => (
                                            <tr key={datingGoal._id}>
                                                <td className='text-center'>{index + 1}</td>
                                                <td>{datingGoal.datingGoalOption}</td>
                                                <td>{moment(datingGoal.datingGoalAddDate).format('YYYY-MM-DD')}</td>
                                                <td>
                                                    <Link to={`/edit-dating-goals/${datingGoal._id}`}><i className="fa-regular fa-pen-to-square"></i></Link> &nbsp;
                                                    <i className="fa-solid fa-trash" onClick={() => handleShow(datingGoal._id)} style={{ cursor: 'pointer' }}></i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DatingGoalsDetails;
