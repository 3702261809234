import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useAuth } from "../AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import axios from "axios";

const DatingGoalsEdit = () => {
  const navigate = useNavigate();
  const { apipath } = useAuth();
  const { datingId } = useParams(); // Assuming the ID comes from the route
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    datingGoalOption: '',
  });

  // Fetch existing dating goal details on component mount
  useEffect(() => {
    const fetchDatingGoalDetails = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${apipath}/dating-goals/details/${datingId}`);
        const data = response.data.datingGoals;
        setFormData({
          datingGoalOption: data.datingGoalOption || '',
        });
      } catch (error) {
        console.error("Error fetching dating goal details:", error);
        iziToast.error({ title: "Error", message: "Failed to load dating goal details." });
      } finally {
        setIsLoading(false);
      }
    };

    fetchDatingGoalDetails();
  }, [apipath, datingId]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission to update the dating goal
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // API call to update the dating goal
      const response = await axios.put(`${apipath}/dating-goals/details/${datingId}`, formData);
      if (response.status === 200) {
        iziToast.success({ title: "Success", message: "Dating goal updated successfully!" });
        navigate("/dating-goals"); // Redirect after successful update
      }
    } catch (error) {
      console.error("Error updating dating goal:", error);
      iziToast.error({ title: "Error", message: "Failed to update dating goal." });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Edit Dating Goal</h5>
            <div className="row nav-column">
              <form className="row" onSubmit={handleSubmit}>
                <div className="col-lg-12 mb-3">
                  <label className="form-label">Dating Goal Option</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter dating goal option"
                    name="datingGoalOption"
                    value={formData.datingGoalOption}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <Link to="/dating-goals">
                    <button className="btn btn-primary">Back</button>
                  </Link>
                  <button type="submit" className="btn btn-primary" disabled={isLoading}>
                    {isLoading ? "Updating..." : "Update Dating Goal"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DatingGoalsEdit;
