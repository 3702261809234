import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import Navbar from './Navbar';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import moment from 'moment';

const QuizzesQuestionDetails = () => {
  const dataTableRef = useRef();
  const [show, setShow] = useState(false);
  const [quizIdToDelete, setQuizIdToDelete] = useState(null);
  const [quizzesCount, setQuizzesCount] = useState(0); // Track number of quizzes
  const [quizzes, setQuizzes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { apipath } = useAuth();
  const [loading, setLoading] = useState(true);
  const maxQuizCount = 10; // Limit to 10 quizzes

  const handleShow = (id) => {
    setQuizIdToDelete(id);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  useEffect(() => {
    fetchQuizzes();
    if (!loading && quizzes.length > 0) {
      $(dataTableRef.current).DataTable();
    }
  }, [loading, quizzes]);

  const fetchQuizzes = async () => {
    try {
      const response = await axios.get(`${apipath}/quizzes-question/details`);
      const filteredQuizzes = response.data.quizzesQuestion;
      setQuizzes(filteredQuizzes);
      setQuizzesCount(filteredQuizzes.length); // Set the current quiz count
      setLoading(false);
    } catch (error) {
      console.error('Error fetching quizzes:', error);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await axios.delete(`${apipath}/quizzes-question/details/${quizIdToDelete}`);
      await fetchQuizzes(); // Refresh quizzes after deletion
      setShow(false);
      setIsLoading(false);
    } catch (error) {
      console.error('Error deleting quiz:', error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className='home'>
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className='bi bi-menu'></i>
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Quizzes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this quiz?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleDelete}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <h5>Quiz Question Details</h5>

            <div className="add-button">
              {/* Disable button if quiz count reaches 10 */}
              {quizzesCount < maxQuizCount ? (
                <Link to="/add-quiz-question">
                  <button>+ Add Quiz Question</button>
                </Link>
              ) : (
                <button disabled>+ Add Quiz Question (Max 10 Reached)</button>
              )}
            </div>

            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Quiz Question</th>
                      <th>Quiz Option 1</th>
                      <th>Quiz Option 2</th>
                      <th>Quiz Option 3</th>
                      <th>Quiz Option 4</th>
                      <th>Joined On</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quizzes.map((quizze, index) => (
                      <tr key={quizze._id}>
                        <td className='text-center'>{index + 1}</td>
                        <td>{quizze.quizQuestion}</td>
                        <td>{quizze.quizOption1}</td>
                        <td>{quizze.quizOption2}</td>
                        <td>{quizze.quizOption3}</td>
                        <td>{quizze.quizOption4}</td>
                        <td>{moment(quizze.quizAddDate).format('YYYY-MM-DD')}</td>
                        <td>
                          <Link to={`/quiz-question-edit/${quizze._id}`}><i className="fa-regular fa-pen-to-square"></i></Link> &nbsp;
                          <i className="fa-solid fa-trash" onClick={() => handleShow(quizze._id)} style={{ cursor: 'pointer' }}></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default QuizzesQuestionDetails;
